import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'principal',
    loadChildren: () => import('./principal/principal.module').then( m => m.PrincipalPageModule)
  },
  {
    path: 'sede',
    loadChildren: () => import('./sede/sede.module').then( m => m.SedePageModule)
  },
  {
    path: 'reporte',
    loadChildren: () => import('./reporte/reporte.module').then( m => m.ReportePageModule)
  },
  {
    path: 'monitoreo',
    loadChildren: () => import('./monitoreo/monitoreo.module').then( m => m.MonitoreoPageModule)
  },
  {
    path: 'instalaciones',
    loadChildren: () => import('./instalaciones/instalaciones.module').then( m => m.InstalacionesPageModule)
  },
  {
    path: 'mapa',
    loadChildren: () => import('./mapa/mapa.module').then( m => m.MapaPageModule)
  },
  {
    path: 'variables',
    loadChildren: () => import('./variables/variables.module').then( m => m.VariablesPageModule)
  },
  {
    path: 'yutaho',
    loadChildren: () => import('./yutaho/yutaho.module').then( m => m.YutahoPageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'sensor',
    loadChildren: () => import('./sensor/sensor.module').then( m => m.SensorPageModule)
  },
  {
    path: 'agendamiento',
    loadChildren: () => import('./agendamiento/agendamiento.module').then( m => m.AgendamientoPageModule)
  },
  {
    path: 'replanteo-form',
    loadChildren: () => import('./replanteo-form/replanteo-form.module').then( m => m.ReplanteoFormPageModule)
  },
  {
    path: 'despacho',
    loadChildren: () => import('./despacho/despacho.module').then( m => m.DespachoPageModule)
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./usuarios/usuarios.module').then( m => m.UsuariosPageModule)
  },
  {
    path: 'recaudos',
    loadChildren: () => import('./recaudos/recaudos.module').then( m => m.RecaudosPageModule)
  },
  {
    path: 'popover2',
    loadChildren: () => import('./popover2/popover2.module').then( m => m.Popover2PageModule)
  },
  {
    path: 'ticket',
    loadChildren: () => import('./ticket/ticket.module').then( m => m.TicketPageModule)
  },  {
    path: 'replanteo-ver',
    loadChildren: () => import('./replanteo-ver/replanteo-ver.module').then( m => m.ReplanteoVerPageModule)
  },
  {
    path: 'tarifas',
    loadChildren: () => import('./tarifas/tarifas.module').then( m => m.TarifasPageModule)
  },
  {
    path: 'popover3',
    loadChildren: () => import('./popover3/popover3.module').then( m => m.Popover3PageModule)
  },
  {
    path: 'editartafirfa',
    loadChildren: () => import('./editartafirfa/editartafirfa.module').then( m => m.EditartafirfaPageModule)
  },
  {
    path: 'mantenimientos',
    loadChildren: () => import('./mantenimientos/mantenimientos.module').then( m => m.MantenimientosPageModule)
  },
  {
    path: 'popover4',
    loadChildren: () => import('./popover4/popover4.module').then( m => m.Popover4PageModule)
  },
  {
    path: 'historico-usuario',
    loadChildren: () => import('./historico-usuario/historico-usuario.module').then( m => m.HistoricoUsuarioPageModule)
  }



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
