import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { NgxEchartsModule } from 'ngx-echarts';
import { } from 'echarts';
import { AgmCoreModule } from '@agm/core';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,CommonModule,  AgmCoreModule.forRoot({
    apiKey: 'AIzaSyDDNunlrlRApoo4bo5L8V5fMcBCTjZDbK8',
    libraries: ['places']}),
    IonicModule.forRoot(), AppRoutingModule, NgbModule, HttpClientModule, NgxEchartsModule.forRoot({
    echarts: () => import('echarts'),
  }),
  AgmCoreModule.forRoot({
    apiKey: 'AIzaSyDDNunlrlRApoo4bo5L8V5fMcBCTjZDbK8',
    libraries: ['places']}),
],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
